module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"manualInit":true,"modulePath":"/codebuild/output/src2803982739/src/gatsby-sdos/src/cms/cms.js","enableIdentityWidget":false,"htmlFavicon":"src/images/Favicon-512x512.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.sandiegooralsurgery.com/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NK5QDXK","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Oral Surgery & Dental Implant Specialists of San Diego","lang":"en","display":"standalone","short_name":"The Oral Surgery & Dental Implant Specialists of San Diego","description":"Board-certified oral surgeons Dr. Shelton and Dr. Koshgerian offer dental implants, wisdom teeth removal, and more in San Diego, CA.","start_url":"/","background_color":"#eafcfb","theme_color":"#0756a6","icon":"src/images/Favicon-512x512.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"d9e462165140e49ca26807062e22f073"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
